











import { Component, Vue, Prop, Watch }            from 'vue-property-decorator'

import NoItems      from '@/components/partials/NoItems.vue'
import SmallLoader  from '@/components/loaders/SmallLoader.vue'
import AuditComment from '@/components/comments/AuditComment.vue'

import { ProposalResource }    from '@/models/proposals/ProposalResource'
import { ProjectResource } from '@/models/projects/ProjectResource'
import { FlexibleDocumentResource } from '@/models/flexibleDocument/FlexibleDocumentResource'

// component
@Component({
  components: {
    NoItems,
    SmallLoader,
    AuditComment
  }
})
export default class FlexibleDocumentIndex extends Vue {

  @Prop()
  private project!: ProjectResource

  @Prop()
  private document!: FlexibleDocumentResource

  // @Watch('document.audits.data', { deep: true })
  // private onProposalChange(proposal: ProposalResource, oldProposal: ProposalResource): void {
  //   this.scrollBottom()
  // }

  // private async created() {
    // this.scrollBottom()
  // }

  // private scrollBottom(): void {
  //   const wrapper = this.$refs.wrapper as HTMLElement
  //   if (!wrapper) return
  //   wrapper.scrollTop = wrapper.scrollHeight
  // }
}
